const { number } = require("echarts");

module.exports = {
  props:{
    userAnswers:{
      type:Array,
    },
    // test->考试 record->记录 analysis->解析
    mode:{
      type:String,
      default:'test',
    },
    testId:{
      type:Number,
    }
  },
  data() {
    return {
      // 客户答案数组
      dataMap: {},
      answerCorrectMap:{},
    };
  },
  computed: {
    disableChange(){
      if(this.userAnswers){
        return this.mode == 'analysis'
      }
    }
  },
  watch:{
    userAnswers:function(newValue,oldValue){
      if(newValue.length>0){
        setTimeout(() => {
          this.setUserAnswer()
        }, 200);
        let obj = {}
        for(let q of newValue){
          obj[q.questionId] = q.isCorrect
        }
        this.answerCorrectMap = obj
      }
    }
  },
  methods: {
    setUserAnswer(i){},
    emitDataList(key, value, optid = '', sectionId = '') {
      if(this.disableChange) return
      let emit_data = { key: key, value: value, optionId: optid, sectionId: sectionId }
      this.$emit('value-change', emit_data, this.dataMap[key]);
      this.dataMap[key] = emit_data;
    },
  }

};
