const kr = {
    'Language': '한국어',
    headertag: [
      {
        index: 0,
        active: false,
        router: "/home",
        Width: "36px",
        margin: "0 0 0 0",
        routerName: "홈",
      },
      {
        index: 1,
        active: false,
        router: "/speaking",
        Width: "72px",
        margin: "0 0 0 57px",
        routerName: "회화 문제 은행",
      },
      {
        index: 2,
        active: false,
        router: "/writing",
        Width: "72px",
        margin: "0 0 0 64px",
        routerName: "쓰기 문제 은행",
      },
      {
        index: 3,
        active: false,
        router: "/listening",
        Width: "86px",
        margin: "0 0 0 50px",
        routerName: "청취 문제 은행",
      },
      {
        index: 4,
        active: false,
        router: "/reading",
        Width: "86px",
        margin: "0 0 0 50px",
        routerName: "독해 문제 은행",
      },
      {
        index: 5,
        active: false,
        router: "/ModelTest",
        Width: "86px",
        margin: "0 0 0 50px",
        routerName: "모의고사",
      },
      {
        index: 6,
        active: false,
        router: "/company-intro",
        Width: "86px",
        margin: "0 0 0 50px",
        routerName: "기업 서비스",
      },
    ],
    Speaking: {
      Speakingbefore: {
        roterHome: '홈',
        roterNmae: '회화 문제 은행',
        plstxt: "검색하세요",
        cardtxt: {
          gotest: '연습 시작',
          showMore: '더 보기',
          up: '접기',
          flname: '분류',
          flList: [
            { name: '전체' },
            { name: '환경' },
            { name: '학습' },
          ],
          xxname: '학습',
          xxList: [
            { name: '전체' },
            { name: '미학습' },
            { name: '학습 완료' },
          ],
          txname: '유형',
          txList: [
            { name: 'Part1' },
            { name: 'Part2' },
          ],
          scname: '즐겨찾기',
          scList: [
            { name: '전체' },
            { name: '미즐겨찾기' },
            { name: '즐겨찾기' },
          ],
          ndname: '난이도',
          ndList: [
            { name: '전체' },
            { name: '쉬움' },
            { name: '보통' },
            { name: '어려움' },
          ],
          pgname: '채점',
          pgList: [
            { name: '전체' },
            { name: 'O' },
            { name: 'X' },
          ],
          xjname: '새로운 문제',
          xjList: [
            { name: '전체' },
            { name: '새 문제' },
          ],
        }
      }
    }
  }
  export default kr;
  