import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
Vue.use(Vuex)

// checkbox1与checkbox2 是写作模考页面part1与part2控制左下的切换样式的

const store = new Vuex.Store({
    modules: {
        user
    },
    state: {
        checkbox1: false,
        checkbox2: false,
        speakNum:0,
        timeStart:false,
        sortValue: null, // 用于存储传递的参数
        sortIsDo: '', // 用于存储传递的参数
        testInfoArr: [],
    },
    mutations: {
        updateCheckbox1(state, newValue) {
            state.checkbox1 = newValue;
        },
        updateCheckbox2(state, newValue) {
            state.checkbox2 = newValue;
        },
        speakValue(state, newValue) {
            state.speakNum = newValue;
        },
        changeTime(state,newValue){
            state.timeStart = newValue
        },
        // 用来同步题号目录选中
        setSortValue(state, value) {
            state.sortValue = value;
        },
        // 用来更改题号完成状态
        setSortIsDo(state, value) {
            state.sortIsDo = value;
        },

        setTestInfoArr(state, payload) {
            state.testInfoArr = payload;
        },
    },
    actions: {
        // 用来同步题号目录选中
        triggerCheckSort({ commit }, value) {
            commit('setSortValue', value);
        },
         // 用来更改题号完成状态
         triggerCheckSortIsDo({ commit }, value) {
            commit('setSortIsDo', value);
        },

        updateTestInfoArr({ commit }, payload) {
            commit('setTestInfoArr', payload);
        },
    }
});


export default store
