import { data } from "@/components/paper/question.mixin";
import http from "./http";
import http_v2 from "./http_v2";
import axios from "axios";
let examId = JSON.parse(sessionStorage.getItem("examId"));
let userId = JSON.parse(localStorage.getItem("loginInfo"))?.id;
export const getExamId = (id) => {
  examId = id;
};
export const getUserId = (id) => {
  userId = id;
};
export default class Api {
  /**
   * 获取二维码  获取登录状态
   * @param {*} params
   * @return
   */
  static getWxCode(sk) {
    return http_v2.get(`/app-api/member/auth/web-qr?sk=${sk}`);
  }
  static getLoginState(key, sk) {
    return http_v2.get(`/app-api/member/auth/check-login?eventKey=${key}`);
  }

  /**
   * 获取用户的个人信息
   * @param {*} params
   * @return
   */
  static getUserInfo() {
    return http_v2.get(`/member/user/get`);
  }

  /**
   * 获取验证码 手机登录
   * @param {*} params
   * @return
   */
  // 发送短信
  static postFormphone(data) {
    return http_v2.post("/app-api/member/auth/send-sms-code", data);
  }
  // 手机号码登录
  static postFormdata(data) {
    return http_v2.post("/app-api/member/auth/sms-login", data);
  }

  /**
   * 获取所有考试类型
   * /exam/all
   * @param {*} data
   * @returns
   */
  static getExamType() {
    return http_v2.get("/exam/all");
  }
  static async getspekTopicList(data = {}) {
    return await http_v2.post("/speaking/topic/home/list/v2", data);
  }
  // 获取口语list
  static async getTopicList(data = {}) {
    return await http_v2.post("/speaking/topic/list/query", data);
  }

  // 获取生成音频次数
  static async getVadioPoint(userId) {
    return await http_v2.post(
      `/system/resource/app?resourceId=8&userId=${userId}&userType=1`
    );
  }
  // 点击生成音频
  static async getMakeVadio(data) {
    return await http_v2.post(`/speaking/answer/ai/generate/audio`, data);
  }

  //获取口语详情
  static async getTopicInfo(topicId, data = {}) {
    return await http_v2.post(`/speaking/topic/info/${topicId}`, data);
  }
  // ai生成part答案
  static async generateAnswer(data = {}) {
    return await http_v2.post("/ai/answer/generate", data);
  }
  // 保存ai生成的答案
  static async savegenerateAnswer(data = {}) {
    return await http_v2.post("/speaking/topic/answer", data);
  }
  // 获取七牛云token
  static async getQiniuToken() {
    return await http_v2.get("/file/getToken");
  }
  // 上传录音到对应的答案下
  static async uploadAudio(data = {}) {
    return await http_v2.post("/speaking/audio/answer", data);
  }
  static async uploadAireport(data = {}) {
    return await http_v2.post("/speaking/eval/ai", data);
  }
  // 获取ai报告
  static async getRepoat(id) {
    return await http_v2.get(`/speaking/eval/ai/${id}`);
  }
  // 录音转文字
  static async audiotoWord(url) {
    return await http_v2.post(`/audio/word?url=${url}`);
  }
  // 获取模考list
  static async getMocklist() {
    return await http_v2.get("/ai/mock/exam");
  }
  static async uploadmockAnswer(data = {}) {
    return await http_v2.post("/mock/single", data);
  }
  //收藏或取消收藏
  static async orFavorite(data = {}) {
    return await http_v2.post("speaking/topic/favorite", data);
  }
  // 生成自定义答案
  static async generate(data = {}) {
    return await http_v2.post("/speaking/answer/ai/generate/v2", data);
  }
  static async translateEn(str) {
    // return await http_v2.get(`/translate/gpt/en?str=${str}`)
    return await http_v2.post(`/speaking/answer/ai/save/answer/audio`, str);
  }
  static async savegenerate(data = {}) {
    return await http_v2.post("/speaking/answer/ai/save/answer", data);
  }
  static async partmock(data = {}) {
    return await http_v2.post("/speaking/v2/mock/topic/single", data);
  }
  static async getwordslist() {
    return await http_v2.get("/tip/words/refresh");
  }

  /**
   * 获取写作部分
   * @param {*} data
   * @returns
   */

  // 获取所有考试下的写作题
  static getWritingAll() {
    console.log("6666");
    return http_v2.get("/writing/all");
  }
  static async postWritingAll(data = {}) {
    return await http_v2.post("/writing/all/search", data);
  }
  static async postWriting(data = {}) {
    return await http_v2.post("/user/writing/save/eassy", data);
  }

  //根据cateId获取大作文或者小作文
  static getWritingID(cateId) {
    return http_v2.get(`/api/writing/all/cate/${cateId}`);
  }
  //根据题目类型获取
  static getWritingType() {
    return http_v2.get("/api/writing/all/topicType");
  }
  // static getUsernew() {
  //   return http.get('/user/infoWeb')
  // }
  static getDetailInfo(cateId) {
    return http_v2.get(`/writing/info/${cateId}`);
  }

  static getOpenAi(prompt) {
    return http_v2.get(`/chat/${prompt}`);
  }

  static async submitDetails(data = {}) {
    return await http_v2.post("/paper/submit/details", data);
  }
  //一次提交多个问题
  static async addAll(data = {}) {
    return await http_v2.post("/paper/question/addAll", data);
  }

  // 个人中心接口
  // 更新个人信息
  static async postUserInfo(data = {}) {
    return await http_v2.put("/app-api/member/user/update", data);
  }

  // 获取收藏内容
  static getUserFavorite() {
    return http_v2.get("/user/saveUserFavorite");
  }
  // 获取口语练习内容
  static getUserSpeaking() {
    return http_v2.get("/speaking/user/saveUserSpeaking");
  }
  // 获取口语模考内容
  static saveUserSpeakingMock() {
    return http_v2.get("/user/saveUserSpeakingMock");
  }

  // 查询订单状态
  static goodsStatus(tradeNo) {
    return http_v2.get(`/pay/state/${tradeNo}`);
  }
  // 充值会员
  static openVIP(data = {}) {
    return http_v2.post("/pay/wx/nativePay", data);
  }
  // 通知支付成功
  static paySuccess(tradeNo) {
    return http_v2.post(`/pay/payNotify/${tradeNo}`);
  }
  // // 获取所有考试下的阅读听力题
  static async getReadlingAll(data = {}) {
    let userId = localStorage.getItem("userId") || "";
    return await http_v2.get(`/paper/all?userId=${userId}&partType=${data}`);
  }
  // 获取听力信息
  static async getListeningAll() {
    return await http_v2.get("/paper/all");
  }
  // // 获取用户提交记录
  static async getQuestionSetAll(userId, type, mode) {
    return await http_v2.get(
      `/paper/submit/record/list?userId=${userId}&type=${type}&mode=${mode}`
    );
  }
  // // 个人中心获取用户练习记录
  static async userGetPracticeAll(userId) {
    return await http_v2.get(`/history/test/overview?userId=${userId}`);
  }
  static async getmock(type, page) {
    return await http_v2.get(`/mock/report/${type}/${page}`);
  }
  static async getonemock(page) {
    return await http_v2.get(`/speaking/v2/mock/topic/report/list/${page}`);
  }
  static async getonemockinfo(id) {
    return await http_v2.get(`/speaking/v2/mock/topic/report/${id}`);
  }
  //获取模考报告list
  static getmockreportlist() {
    return http_v2.get("/ai/mock/report");
  }
  static getmockinfo(id) {
    return http_v2.get(`/mock/report/${id}`);
  }

  //根据套卷类型分页获取所有的子数据
  static async getSonInfoAll(paperType, paperNum, paperSize) {
    return await http_v2.get(
      `/paper/paperVoTree/${paperType}/${paperNum}/${paperSize}`
    );
  }
  //  根据partid查询part详情
  static async getpartDetail(partId) {
    return await http_v2.get(`/paper/part/detail?partId=${partId}`);
  }
  //  根据partid查询part详情
  static async getYiPin(partId) {
    return await http_v2.get(`/paperMaterial/${partId}`);
  }
  // 根据testid查询test详情用于模考
  static async getTestDetail(testId, partType) {
    return await http_v2.get(
      `/paper/test/detail?testId=${testId}&partType=${partType}`
    );
  }
  // 添加做题记录
  // static async recordAdd(data = {}) {
  //   return await http.post('/paper/submit/record/add', data);
  // }
  // 2.0添加做题记录
  static async recordAdd(data = {}) {
    return await http_v2.post("/app-api/ielts/submit/new-record", data);
  }

  // 2.0添加做题写作
  static async recordAddWriteling(data = {}) {
    return await http_v2.post("/app-api/ai/writing/new-record", data);
  }
  // 提交考试内容 写作
  static async setAllAnswerWriteling(data) {
    return http_v2.post("/app-api/ai/writing/correct", data);
  }
  // 提交答案  写作
  static async setUserAnswerWriteling(data) {
    return http_v2.post("/app-api/ai/writing/submit-answer", data);
  }
  // 获取某一部分详情 写作批改
  static async getcorrectionstatusWriteling(data) {
    return http_v2.get("/app-api/ai/writing/correct-status", data);
  }
  // 重新批改  写作
  static async getAnswerHistoryWriteling(data) {
    return await http_v2.get("/app-api/ai/writing/history-detail", data);
  }

  // 获取上次答题的提交记录  写作
  static async getretryWriteling(data) {
    return await http_v2.get("/app-api/ai/writing/retry-correct", data);
  }

  // 获取批改记录  写作
  static async getwritinghistory(data) {
    return await http_v2.get("/app-api/ai/writing/history", data);
  }

  // 获取当前用户还剩下多少资源点
  static async getUserResource() {
    return await http_v2.get("/app-api/pay/wallet/get");
  }
  // 获取当前用户还剩下多少功能次数
  static async getUserFunFreNow(data) {
    return await http_v2.get("/system/resource/app", data);
  }

  // 获取u点价格
  static async getwelletlist() {
    return await http_v2.get("/pay/wallet-recharge-package/list");
  }

  // 创建u点订单
  static async walletrecharge(data) {
    return await http_v2.post("/pay/wallet-recharge/create", data);
  }

  // 重新生成报告  写作
  static async getwritingretry(data) {
    return await http_v2.get("/app-api/ai/writing/retry-generate-report", data);
  }

  // 扣除用户u点次数
  static async getmapping() {
    return await http_v2.get("/user/resource/mapping");
  }

  // 2.0获取做题记录
  static async getDoHistory(data = {}) {
    return await http_v2.get("/paper/submit/history/detail", data);
  }
  // 提交卷子
  static async recordSave(data = {}) {
    return await http_v2.post("/paper/submit/record/save", data);
  }
  // 获取做过的part记录
  static async gethistoryparts(data = {}) {
    return await http_v2.get("/history/parts", data);
  }
  // 获取做题记录解析详情
  static async gethistorydetails(userId, submitId) {
    return await http_v2.get(
      `/history/details?userId=${userId}&submitId=${submitId}`
    );
  }
  static async getmocktopiclist(topicId, page) {
    return await http_v2.get(
      `/speaking/v2/mock/single/topic/${topicId}/report/list/${page}`
    );
  }

  //套题模考的页面
  //获取所有套题内容
  static async getmoduleparts(data = {}) {
    return await http_v2.get("/paper/all", data);
  }
  //获取做题记录
  static async getQuestHistory(data = {}) {
    return await http_v2.get("/app-api/ai/writing/history", data);
  }
  // 获取考卷详情
  static async getKaojuanxiangqing(data) {
    return await http_v2.get("/app-api/ielts/submit/history-detail", data);
  }

  // 获取某一部分详情
  static async getBufengxiangqin(data) {
    return await http_v2.get("/paper/part/detail", data);
  }
  // static async getAnswerUser(data) {
  //   return await http_v2.get('/history/list',data)
  // }
  // 获取答题记录（个人中心）
  static async getAnswerUser(data) {
    return await http_v2.get("/app-api/ielts/submit/history", data);
  }
  // 获取上次答题的提交记录
  static async getAnswerHistory(data) {
    return await http_v2.get("/history/answers", data);
  }
  // 获取全类型模考
  static async getAllMode(data) {
    return await http_v2.get("/paper/trueTest", data);
  }
  // 获取套题模考记录
  static async getAllModeHistory(data) {
    return await http_v2.get("/history/trueTest", data);
  }
  // VIP套餐列表
  static async VIPList(data) {
    return await http_v2.get("/vip/info/list", data);
  }
  // 创建订单
  static async payEWM(data) {
    return await http_v2.post("/trade/order/create", data);
  }
  // vip倒计时
  static async VIPtime(busName) {
    return await http_v2.get(`/vip/discount/time/${busName}`);
  }

  // 获取考卷类型 （听力/阅读/写作）
  static async getKaojuanleixin(data) {
    return http_v2.get("/paper/list", data);
  }
  static async getReadKaojuanleixin(data) {
    return http_v2.get("/app-api/ielts/paper/list", data);
  }

  // 阅读2.0
  static async getPartleixin(data) {
    return http_v2.get("/app-api/ielts/part/list?id=" + data);
  }
  // 阅读2.0上传答案
  static async uploadReadAnswer(data) {
    return http_v2.post("/app-api/ielts/submit/corrects", data);
  }
  // 阅读2.0提交答案
  // static async setReadAnswer(data) {
  //   return http_v2.post("/app-api/ielts/submit/save", data);
  // }

  //模考首次做完题目后跳转详情页直接获取分数
  static async getScore(data) {
    return http_v2.get("/ielts/submit/mock-info", data);
  }

  //获取个人信息
  static async getUserData(data) {
    return http_v2.get("/system/user/profile/get", data);
  }
  //获取个人某项功能的剩余次数、点数
  static async getUserFunFreNow(data) {
    return http_v2.get("/system/resource/app", data);
  }

  // 获取某一部分详情
  static async getKaojuanxiangqing(data) {
    return http_v2.get("/app-api/ielts/submit/history-detail", data);
  }
  static async getReadxiangqing(data) {
    return http_v2.get("/app-api/ielts/part/list", data);
  }
  // 提交答案
  static async setUserAnswer(data) {
    return http_v2.post("/app-api/ielts/submit/answer", data);
  }
  // 创建提交记录
  // static async setHistory(data) {
  //   return http_v2.post("/app-api/ielts/submit/new-record", data);
  // }
  // 提交考试内容
  static async setAllAnswer(data) {
    return http_v2.post("/app-api/ielts/submit/save", data);
  }
  // 图片转文字
  static async postImgParse(data) {
    return http_v2.post("/ai/ocr/convert", data);
  }
  // 获取跳转小程序模考的二维码
  static async toAppMock(path, since) {
    return http_v2.get(`/mini/qrcode/generate?page=${path}&scene=id=${since}`);
  }

  // 写作题库 写作批改
  static async postWritings(data) {
    // axios.defaults.baseURL = 'https://api.mobile.yboshi.com/'

    // // axios.defaults.baseURL = 'http://114.67.241.84:59187'
    // axios.defaults.headers.common['ybs-user-key'] = localStorage.getItem('userId')
    // return axios.post('/writing/correct', data)

    return http_v2.post("/writing/correct", data);
  }

  // 批改报告
  static async getwritingstatus(data) {
    // axios.defaults.baseURL = 'https://api.mobile.yboshi.com/'

    // // axios.defaults.baseURL = 'http://114.67.241.84:59187'
    // return axios.get('/writing/status', {
    //   params: data,
    //   headers: {
    //     "ybs-user-key": localStorage.getItem('userId')
    //   }
    // })

    return http_v2.get("/writing/status", data);
  }

  //获取指定考试的写作题
  static getWritingList() {
    return http_v2.get("/api/writing/all/exam/{examId}");
  }

  // 获取某一部分详情 写作批改
  static async getcorrectionstatus(data) {
    // axios.defaults.baseURL = 'https://api.mobile.yboshi.com/'

    // // axios.defaults.baseURL = 'http://114.67.241.84:59187'
    // return axios.get('/writing/ai/check/status', {
    //   params: data,
    //   headers: {
    //     "ybs-user-key": localStorage.getItem('userId')
    //   }
    // })
    return http_v2.get("/app-api/ai/writing/history-detail", data);
  }

  // 写作批改 提交
  static async postcheck(data) {
    // axios.defaults.baseURL = 'https://api.mobile.yboshi.com/'

    // // axios.defaults.baseURL = 'http://114.67.241.84:59187'
    // axios.defaults.headers.common['ybs-user-key'] = localStorage.getItem('userId')
    // return axios.post('/writing/ai/check', data)

    return http_v2.post("/writing/ai/check", data);
  }
  // 图片转文字
  static async postImgParse(data) {
    return http_v2.post("/ai/ocr/convert", data);
  }

  //分享 链接
  static getShare() {
    return http_v2.get("/app-api/member/share/url");
  }

  //分享 获取分享统计
  static getSharecount() {
    return http_v2.get("/app-api/member/share/count");
  }

  //分享 获取分享code
  static getSharecode() {
    return http_v2.get("/app-api/member/share/code");
  }
  //获取我的班级信息
  static getMyClassList() {
    return http_v2.get("/classroom/my/list");
  }
  //获取我的班级详情信息
  static getMyClassDetail(id) {
    return http_v2.get(`/classroom/my/detail?classId=${id}`);
  }
  //获取班级作业信息
  static getMyClassWork(id) {
    return http_v2.get(`/classroom/work-record/list?classId=${id}`);
  }
  //获取班级内学生的作业提交记录
  static getClassStudentWork(id, pageSize, pageNo) {
    return http_v2.get(
      `/classroom/detail-member/submits?classId=${id}&pageSize=${pageSize}&pageNo=${pageNo}`
    );
  }
  //获取我的作业提交记录
  static getMyWork(pageSize, pageNo) {
    return http_v2.get(
      `/classroom/work-record/self-list?pageSize=${pageSize}&pageNo=${pageNo}`
    );
  }
  //获取作业完成或未完成作业的列表 ,为未完成，1未完成
  static getMyWorkStateList(status, pageSize, pageNo) {
    return http_v2.get(
      `/classroom/work-record/infos?status=${status}&pageSize=${pageSize}&pageNo=${pageNo}`
    );
  }
}
